const appName = 'Keep Track of Date'

export function createTitle(subtitle?: string | string[]) {
  if (!subtitle) {
    return appName
  }

  const subtitleParts = Array.isArray(subtitle) ? subtitle : [subtitle]
  return [...subtitleParts, appName].join(' | ')
}
